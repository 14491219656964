<template>
  <v-container>
    <section align="center" v-if="!signedIn">
      <h4 class="text-h4">Welcome to Vantage DX</h4>
      <p>Use this tool to validate prerequisites for Vantage DX. </p>
      <p>To get started, please sign-in below using an administrator account for the tenant. When you are prompted to grant permissions, click <b>Accept</b>.</p>
    </section>
    <section>
      <v-row class="d-flex flex-row-reverse" v-if="userEmail" justify="right">
        <v-btn v-on:click="SignOut" style="margin-left: 10px" text small>Sign Out</v-btn>
        <p><b>{{userEmail}}</b></p>
      </v-row>
    </section>
    <section>
      <v-row class="d-flex flex-column align-center" v-if="signedIn && !permissionsGranted" data-test-id="permissionsGranted.row.false">
        <h4 class="text-h4">Permissions <span style="color:#C62828;"><b>not granted</b></span></h4>
        <p>The application does not have permission to continue</p>
        <v-btn x-large>
          <a :href="grantingPermissionsUrl">
            <img src="../assets/images/martello-vantage.svg" height="50px"/>
          </a>
        </v-btn>
      </v-row>
      <v-row v-if="signedIn && permissionsGranted" justify="center" data-test-id="permissionsGranted.row.true">
        <h4 class="text-h4">Permissions <span style="color:#2E7D32;"><b>Granted</b></span></h4>
        <p>To continue, please validate prerequisites for Microsoft Teams Call Quality Dashboard (CQD).</p>
        <div>
          <p class="subtitle-2" align="center">Validate Prerequisites</p>
          <v-btn x-large data-test-id="verification.btn.teamsCqd"><router-link to="teams-cqd-verification"><img src="../assets/images/microsoft-teams-logo.svg" height="50px"/></router-link></v-btn>
        </div>
      </v-row>
    </section>
    <section>
      <v-row v-if="!signedIn" justify="center">
        <h6 class="text-h6 text-light">Sign-in with an admin account for your tenant</h6>
        <v-btn data-test-id="authentication.btn.signIn" v-on:click="SignIn" x-large><img src="../assets/images/microsoft-365-logo.svg" alt="" style="height: 50px"></v-btn>
      </v-row>
    </section>
  </v-container>
</template>

<script>
  import * as CallAPI from '../utils/callAPI';
  import * as auth from '../auth/auth';
  import {msalConfig} from '../auth/authConfig';

  export default {
    name: 'LandingPage',

    data () {
      return {
        signedIn : false,
        userEmail: '',
        permissionsGranted: false,
        grantingPermissions: false,
        grantingPermissionsUrl: ''
      };
    },
    async created () {
      this.authConfig = await msalConfig();
      this.grantingPermissionsUrl = 'https://login.microsoftonline.com/common/adminconsent?client_id='+this.authConfig.auth.clientId+'&redirect_uri='+this.authConfig.auth.redirectUri;
    },
    methods: {
      async SignIn () {     
        this.userEmail = await auth.signIn();
        if(this.userEmail){
          this.signedIn = true;
        } else {
          this.signedIn = false;
        }
        this.getPermissions();

        if(!this.permissionsGranted){
          this.checkPermissions();
        }

      },
      async SignOut () { 
        await auth.signOut().then(this.signedIn = false);  
        this.userEmail = '';
      },

      async getPermissions () {
        let permissions = await CallAPI.getServicePrincipalPermissions();
        if(permissions.length>0){
          this.permissionsGranted = 
            permissions.includes('RoleManagement.Read.Directory') &&
            permissions.includes('Reports.Read.All') &&
            permissions.includes('User.Read')&&
            permissions.includes('User.Read.All');
        }
      },
      async checkPermissions (){
        this.grantingPermissions = true;
        while(!this.permissionsGranted){
          console.log('checking for permissions...');
          await new Promise(resolve => setTimeout(resolve, 2000));
          try {
            await this.getPermissions();
          } catch (e) {
            console.log(e);
          }
        }
        this.grantingPermissions = false;
      }
    },
    async mounted (){
      this.userEmail = await CallAPI.selectAccount();

      if (this.userEmail) {
        this.signedIn = true;
        this.getPermissions(); 
      }

      if (!this.permissionsGranted) {
        this.checkPermissions();
      }
    }
    
  };
</script>

<style scoped>

hr {
  border: 0;
  clear:both;
  display:block;
  width: 100%;               
  background-color:lightgray;
  height: 1px;
  margin-bottom: 30px;
}

.v-btn__content .router-link-active {
  text-decoration: none;
  color: #d12127;
}

.v-application a {
  color: #d12127;
}

</style>
