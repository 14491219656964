/**
 * Configuration object to be passed to MSAL instance on creation. 
 * For a full list of MSAL.js configuration parameters, visit:
 * https://github.com/AzureAD/microsoft-authentication-library-for-js/blob/dev/lib/msal-browser/docs/configuration.md 
 */

function msalConfig(){
let  msalConfig = fetch('/azuredata')
.then(response => response.json())
.then(function(data) {
   
    let authority = data.authorityWorldwide;
    if(window.endpointRegions === "china" )  authority = data.authorityChina;
    else if(window.endpointRegions === "us" ) authority = data.authorityUS;
    else if(window.endpointRegions === "dod" ) authority = data.authorityDoD;
    console.log("Endpoint region authConfig " + authority)
	let  msalConfig = {
    auth: {
        clientId: data.clientId, // This is the ONLY mandatory field that you need to supply.
        authority: authority, // Defaults to "https://login.microsoftonline.com/common"
        redirectUri: data.redirectUri, // You must register this URI on Azure Portal/App Registration. Defaults to window.location.href
        postLogoutRedirectUri : data.postLogoutRedirect,
    },
    cache: {
        cacheLocation: "localStorage", // This configures where your cache will be stored
        storeAuthStateInCookie: false, // Set this to "true" if you are having issues on IE11 or Edge
    },
    
};
return msalConfig;  
	});
    return msalConfig;
}
 

// Add here the endpoints for MS Graph API services you would like to use.
const graphConfig = {
graphActiveDirectory: {
    type: "checkAzureActiveDirectory",
    scopes: ["User.Read.All"]
},
graphRoleManagement: {
    type: "checkCorrectlyAssigned",
    scopes: ["RoleManagement.Read.Directory"]
},
graphMultiFactor: {
    type: "checkMultiFactorDisabled",
    scopes: ["Reports.Read.All"]
},
graphFederation: {
    type: "checkNotFederated",
    scopes: ["User.Read.All"]
},
graphDirectory: {
    type: "graphDirectory",
    scopes: ["Directory.Read.All"]
}
};


const loginRequest = {
scopes: ["User.Read", "User.Read.All", "RoleManagement.Read.Directory", "Reports.Read.All", "Directory.Read.All"]
};

export { msalConfig, graphConfig, loginRequest,};

