import * as graph from './graph.js';
import * as authConfig from '../auth/authConfig';
import * as msal from '@azure/msal-browser';

const wantedRoles = ["f2ef992c-3afb-46b9-b7cf-a126ee74c451","f70938a0-fc10-4177-9e90-2178f8765737","62e90394-69f5-4237-9190-012177145e10","baf37b3a-610e-45da-9e62-d9d1e5e8914b","75941009-915a-4869-abe7-691bff18279e"];
const teamsAdmin = "69091246-20e8-4a56-aa4d-066075b2a7a8";
const failMFMessage = "Multi-factor authentication cannot be verified. This requirement can be validated only with a Microsoft 365 account that has a Global Reader role assigned to it.";
const worldwideEP = "https://graph.microsoft.com/";
const dodEP = "https://dod-graph.microsoft.us/";
const usGovernmentEP = "https://graph.microsoft.us/";
const chinaEP = "https://microsoftgraph.chinacloudapi.cn/";
let username = "";
let checksMap = new Map();
let checkCqdAccountError = "";

async function selectAccount() {
    let myMsalClient = await authConfig.msalConfig().then(function(config){ return new msal.PublicClientApplication(config) });
    
    const currentAccounts = myMsalClient.getAllAccounts();

    if (currentAccounts === null) {
        return;
    } else if (currentAccounts.length > 1) {
        // Add choose account code here
        console.warn("Multiple accounts detected.");
    } else if (currentAccounts.length === 1) {
        username = currentAccounts[0].username;
        return username;
    }
}

async function checkall(){
    checkCqdAccountError='';
    //arg 0 is the cqdAccount
    let cqdAccount = arguments[0];
    let checkCqdAccount = await checkRightCqdAccount(cqdAccount);
    if(checkCqdAccount.value.length>=1){
        await checkAzureActiveDirectory(cqdAccount);
        await checkMultiFactorDisabled(cqdAccount);
        await checkNotFederated(cqdAccount);
        await checkCorrectlyAssigned(cqdAccount); 
    }else {
        checkCqdAccountError = "this cqd account doesnt exist";
    }
}

 
async function checkAzureActiveDirectory() {
    await selectAccount();
    let myMsalClient = await authConfig.msalConfig().then(function(config){ return new msal.PublicClientApplication(config) });    
    let endpoint = getEndpointRegion(window.endpointRegions);
    let check = false;
    let cqdUserName = arguments[0];
    await graph.getGraphClient({
        account: myMsalClient.getAccountByUsername(username),
        scopes: authConfig.graphConfig.graphActiveDirectory.scopes,
        interactionType: msal.InteractionType.Silent,

    // eslint-disable-next-line no-useless-escape
    }).api(`${endpoint}v1.0/users`).filter(`userType eq 'Member' AND userPrincipalName eq '${cqdUserName}'`).get()
        .then((response) => {
            if(response.value.length>=1){
                check = true
            }else check = false;
            checksMap.set(authConfig.graphConfig.graphActiveDirectory.type,check);
        }).catch((error) => {
            console.log('checkAzureActiveDirectory' + error +'%d Erroned Account: '+ cqdUserName);
        }); 
        
}
async function checkMultiFactorDisabled(){
    await selectAccount();
    let check = false;
    let myMsalClient = await authConfig.msalConfig().then(function(config){ return new msal.PublicClientApplication(config) });
    let cqdUserName = arguments[0];
    let endpoint = getEndpointRegion(window.endpointRegions);
    await graph.getGraphClient({
        account: myMsalClient.getAccountByUsername(username),
        scopes: authConfig.graphConfig.graphMultiFactor.scopes,
        interactionType: msal.InteractionType.Silent
    }).api(`${endpoint}beta/reports/credentialUserRegistrationDetails`)
        .version('beta')
        .filter(`isMfaRegistered eq false AND userPrincipalName eq '${cqdUserName}'`)
        .get()
        .then((response) => {
            if(response.value.length>=1){
                check = true
            }else check = false;
            checksMap.set(authConfig.graphConfig.graphMultiFactor.type,check);
        }).catch(() => {
            console.error(failMFMessage +'%d Erroned Account: '+ cqdUserName);
            checksMap.set(authConfig.graphConfig.graphMultiFactor.type,failMFMessage);
        });

}
async function checkNotFederated(){
    await selectAccount();
    let check = false;
    let myMsalClient = await authConfig.msalConfig().then(function(config){ return new msal.PublicClientApplication(config) });
    let cqdUserName = arguments[0];
    let endpoint = getEndpointRegion(window.endpointRegions);
    await graph.getGraphClient({
        account: myMsalClient.getAccountByUsername(username),
        scopes: authConfig.graphConfig.graphFederation.scopes,
        interactionType: msal.InteractionType.Silent,
    }).api(`${endpoint}v1.0/users`).filter('userPrincipalName eq \''+cqdUserName+'\'').select('onPremisesImmutableId').get()
        .then((response) => {
            if(response.value[0].onPremisesImmutableId ===null){
                check = true
            }else check = false;
            checksMap.set(authConfig.graphConfig.graphFederation.type,check);
        }).catch((error) => {
            console.log('checkNotFederated' + error +' Erroned Account: '+ cqdUserName);
        }); 

}
async function checkCorrectlyAssigned(){
    await selectAccount();
    let check = false;
    let myMsalClient = await authConfig.msalConfig().then(function(config){ return new msal.PublicClientApplication(config) });
    let cqdUserName = arguments[0];
    let endpoint = getEndpointRegion(window.endpointRegions);
    //get the accountId of the cqd account
    let accountId = await graph.getGraphClient({
        account: myMsalClient.getAccountByUsername(username),
        scopes: authConfig.graphConfig.graphRoleManagement.scopes,
        interactionType: msal.InteractionType.Silent,
    }).api(`${endpoint}v1.0/users`).filter('userPrincipalName eq \''+cqdUserName+'\'').select('id').get();
    await graph.getGraphClient({
        account: myMsalClient.getAccountByUsername(username),
        scopes: authConfig.graphConfig.graphRoleManagement.scopes,
        interactionType: msal.InteractionType.Silent,
    }).api(endpoint+'v1.0/roleManagement/directory/roleAssignments').filter(`principalId eq '${accountId.value[0].id}'`).get()
        .then((response) => {
            if(response.value.length!==0){
                let firstCheck = false;
                let secondCheck = false;
                //check if the roles are in the wanted roleList
                for (let i = 0; i < response.value.length; i++) {
                    let roleDefinitionId = response.value[i].roleDefinitionId;
                    if(wantedRoles.includes(roleDefinitionId)){
                        firstCheck = true;
                        break;
                    }
                }                              
                //check if the roles are not equal to the Teams Administration one
                for (let i = 0; i < response.value.length; i++) {
                    let roleDefinitionId = response.value[i].roleDefinitionId;
                    if(teamsAdmin===roleDefinitionId ){
                        secondCheck = false;
                        break;
                    }else secondCheck = true;
                }
                check = firstCheck && secondCheck;

            }
            checksMap.set(authConfig.graphConfig.graphRoleManagement.type,check);
        }).catch((error) => {
            console.log('checkCorrectlyAssigned' + error +'%d Erroned Account: '+ cqdUserName);
        }); 

}

async function checkRightCqdAccount(){
    await selectAccount();
    let myMsalClient = await authConfig.msalConfig().then(function(config){ return new msal.PublicClientApplication(config) });
    let cqdUserName = arguments[0];
    let endpoint = getEndpointRegion(window.endpointRegions);
    return await graph.getGraphClient({
        account: myMsalClient.getAccountByUsername(username),
        scopes: authConfig.graphConfig.graphFederation.scopes,
        interactionType: msal.InteractionType.Silent,
    }).api(endpoint + 'v1.0/users').filter(`userPrincipalName eq '${cqdUserName}'`).get()
       .catch((error) => {
            console.log('checkRightCqdAccount' + error +' Erroned Account: '+ cqdUserName);
        }); 

}

async function getServicePrincipalId(){
    await selectAccount();
    let myMsalClient = await authConfig.msalConfig().then(function(config){ return new msal.PublicClientApplication(config) });
    let appId = myMsalClient.config.auth.clientId;
    let endpoint = getEndpointRegion(window.endpointRegions);
    console.log("endpointService");
    console.log(endpoint);
    let serviceId = '';
    await graph.getGraphClient({
        account: myMsalClient.getAccountByUsername(username),
        scopes: authConfig.graphConfig.graphDirectory.scopes,
        interactionType: msal.InteractionType.Silent,
    }).api(endpoint+'v1.0/servicePrincipals').filter(`appId  eq '${appId}'`).get()
        .then((response) => {
            if(response.value.length>0){
                serviceId = response.value[0].id;
            }
        }).catch((error) => {
            console.log(error +'%d Erroned Account: '+ username);
        }); 
    
    return serviceId;
}

async function getServicePrincipalPermissions(){
    await selectAccount();
    let myMsalClient = await authConfig.msalConfig().then(function(config){ return new msal.PublicClientApplication(config) });
    let endpoint = getEndpointRegion(window.endpointRegions);
    let id = await getServicePrincipalId();
    let consentType = 'AllPrincipals';
    let permissions = '';
    if(id!=''){
        await graph.getGraphClient({
            account: myMsalClient.getAccountByUsername(username),
            scopes: authConfig.graphConfig.graphDirectory.scopes,
            interactionType: msal.InteractionType.Silent,
        }).api(endpoint+'v1.0/servicePrincipals/'+id+'/oauth2PermissionGrants').get()
            .then((response) => {
                permissions = response.value.filter(res=>res.consentType==consentType)[0].scope.split(" ");
            }).catch((error) => {
                console.log(error +'%d Erroned Account: '+ username);
            }); 
    }
    
    return permissions;
}

function getEndpointRegion(region){
    switch (region) {
        case "worldwide":
            return worldwideEP;
        case "china":
            return chinaEP;
        case "us":
            return usGovernmentEP;
        case "dod":
            return dodEP;
    }

}

export {checkall, checksMap, selectAccount, getServicePrincipalPermissions, checkCqdAccountError}

