import * as authConfig from './authConfig';
import * as msal from '@azure/msal-browser';




let username = "";
let signedIn = false;

async function selectAccount() {
    let myMsalClient = await authConfig.msalConfig().then(function(config){ return new msal.PublicClientApplication(config) });
    const currentAccounts = myMsalClient.getAllAccounts();

    if (currentAccounts === null) {
        return;
    } else if (currentAccounts.length > 1) {
        // Add choose account code here
        console.warn("Multiple accounts detected.");
    } else if (currentAccounts.length === 1) {
        username = currentAccounts[0].username;
    }
}

function handleResponse(response) {

    if (response !== null) {
        username = response.account.username;
    } else {
        selectAccount();
    }
}

async function signIn() {
    signedIn = true;
    let myMsalClient = await authConfig.msalConfig().then(function(config){ return new msal.PublicClientApplication(config) });
    await myMsalClient.loginPopup(authConfig.loginRequest)
        .then(handleResponse)
        .catch(error => {
            console.error(error +'%d Erroned Account: '+ username);
        });
    return username;
}

async function signOut() {
    signedIn  = false
    let myMsalClient = await authConfig.msalConfig().then(function(config){ return new msal.PublicClientApplication(config) });
    const logoutRequest = {
        account: myMsalClient.getAccountByUsername(username)
    };

    myMsalClient.logoutRedirect(logoutRequest);
    myMsalClient.handleRedirectPromise();

}
export { signIn, signOut, signedIn }