<template>
  <v-container>
    <section align="center">
      <h4 class="text-h4">Welcome to Vantage DX</h4>
      <p>Use this tool to validate prerequisites for Vantage DX.</p>
      <p>
        To get started, please select which Microsoft 365 Subscription your
        tenant is a part of.
      </p>
    </section>
    <section>
      <v-row justify="center">
        <v-select
          id="endpointRegion"
          :items="regions"
          label="Microsoft 365 Subscription*"
          v-model="endpointRegion"
          item-text="value"
          item-value="key"
          v-on:change="setEndpointRegion"
        ></v-select>
      </v-row>
      <v-row justify="center">
        <div align="center">
          <v-btn  x-large data-test-id="endpointSelection.btn.continue" color="#d12127" outlined v-on:click="selectEndpoint">CONTINUE</v-btn>
        </div>
      </v-row>
    </section>
  </v-container>
</template>

<script>
window.endpointRegions = "worldwide";
export default {
  data() {
    return {
      endpointRegion: "worldwide",
      regions: [
        { key: "worldwide", value: "Worldwide" },
        { key: "dod", value: "U.S. Government DoD" },
        { key: "us", value: "U.S. Government GCC High" },
        { key: "china", value: "Microsoft 365 China" }
      ],
    };
  },
  methods: {
    setEndpointRegion() {
      window.endpointRegions = this.endpointRegion;
    },

    selectEndpoint() {
      this.$router.push("/sign-in");
       window.endpointRegions = this.endpointRegion;
    },
  },
};
</script>



<style scoped>

hr {
  border: 0;
  clear:both;
  display:block;
  width: 100%;               
  background-color:lightgray;
  height: 1px;
  margin-bottom: 30px;
}

.v-btn__content .router-link-active {
  text-decoration: none;
  color: #d12127;
}

.v-application a {
  color: #d12127;
}

</style>