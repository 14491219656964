<template>
    <v-row class="criteria-wrap">
        <v-icon :color="iconColor" style="position: absolute;">{{validationIcon}}</v-icon>
        <p class="criteria-text"><slot/></p>
        <p class="criteria-error  text-subtitle">{{errorMessage}}</p>
    </v-row>
</template>
<script>
export default {
  name: 'VerificationCriteria',
  props: {
      validationStatus: String,
      errorMessage: String
  },
  computed: {
      validationIcon () {
          if (this.validationStatus == 'valid') {
              return 'mdi-checkbox-marked'
          } else if (this.validationStatus == 'invalid') {
              return 'mdi-close-box'
          }
          return 'mdi-circle-medium'
      },

      iconColor () {
          if (this.validationStatus == 'valid') {
              return '#2E7D32'
          } else if (this.validationStatus == 'invalid') {
              return '#C62828'
          }
          return ''
      }
  }
};
</script>
<style scoped>

    p {
        margin-bottom: 0;
    }
    .criteria-wrap{
        margin-bottom: 20px;
        display: block;
    }
    .criteria-text{
        margin-left: 30px;
    }
    .criteria-error{
        color:red; 
        margin-left: 30px; 
        margin-top:-10; 
        font-size: 12px
    }
</style>
