import Vue from 'vue'
import VueRouter from 'vue-router'
import LandingPage from '../pages/LandingPage'
import TeamsCqdVerification from '../pages/TeamsCqdVerification/index'
import welcomePage from '../pages/WelcomePage'

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'home',
    component: welcomePage
  },
  {
    path: '/sign-in',
    name: 'sign-in',
    component: LandingPage
  },
  {
    path: '/teams-cqd-verification',
    name: 'teams-cqd-verification',
    component: TeamsCqdVerification
  }
]

const router = new VueRouter({
  routes
})

export default router
