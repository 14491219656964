<template>
  <v-container>
    <section>
      <v-row class="d-flex flex-row-reverse" v-if="adminEmail" justify="right">
        <v-btn v-on:click="SignOut" style="margin-left: 10px" text small>Sign Out</v-btn>
        <p><b>{{adminEmail}}</b></p>
      </v-row>
    </section>
    <section align="center">
      <h4 class="text-h4">Validate Account</h4>
      <p>Verify that the account you use for the integration with the Microsoft Teams Call Quality Dashboard (CQD) meets the following requirements:</p>
    </section>
    <section>
      <v-row v-if="!validationCompleted && runningChecks">
        <h4 class="text-h4">Validating...</h4>
        <v-progress-linear
          indeterminate
          color="#d12127"
        ></v-progress-linear>
      </v-row>
      <v-row v-if="validationCompleted">
        <h4 data-test-id="validationChecks.text.validationMessage" class="text-h4">Validation <span :style="{color:validationPassed?'#2E7D32':'#C62828', fontWeight: '500'}">{{validationPassed?'Passed':'Failed'}}</span></h4>
      </v-row>
    </section>
    <section>
      <verification-criteria :validationStatus="checks.checkAzureActiveDirectory">The Microsoft 365 account is configured in Azure Active Directory</verification-criteria>
      <verification-criteria :validationStatus="checks.checkMultiFactorDisabled" :errorMessage="failMessage?failMessage:''">Multi-factor authentication is disabled</verification-criteria>
      <verification-criteria :validationStatus="checks.checkNotFederated">The Microsoft 365 is not federated</verification-criteria>
      <verification-criteria :validationStatus="checks.checkCorrectlyAssigned">The Microsoft 365 account must be assigned a <b>Teams Communication Support Engineer role</b> or a <b>Global Reader role</b>. The account must have permission to access <a href="https://docs.microsoft.com/en-us/microsoftteams/turning-on-and-using-call-quality-dashboard#assign-roles-for-accessing-cqd">end user identifiable information (EUII)</a>.</verification-criteria>
      <div v-if="!signedIn" style="display: flex;">
        <v-icon style="margin-bottom: 16px;">mdi-information-outline</v-icon>
        <p style="margin-left: 5px;">We recommend that you do not use a Teams Administrator role for this purpose</p>
      </div>
      
    </section>

    <section>
      <v-row v-if="!signedIn" justify="center">
        <h6 class="text-h6 text-light">Sign-in to validate prerequisites</h6>
        <p class="text-caption" align="center">Please use the Microsoft 365 account that will be used for the Microsoft Teams CQD integration.</p>
        <v-btn data-test-id="authentication.btn.signIn" v-on:click="SignIn" x-large><img src="../../assets/images/microsoft-365-logo.svg" alt="" style="height: 50px"></v-btn>
      </v-row>
      
      <!-- Input email for validation -->
      <v-row v-if="signedIn">
        <v-text-field v-model="cqdEmail" color="red" placeholder="Account used for CQD Integration*" :rules="[validateEmail, msg]" :disabled="validationCompleted" style="margin-bottom: 30px;" data-test-id="validationForm.textField.cqdEmail"/>
      </v-row>
      
      <v-row v-if="!validationPassed" justify="center">
        <!-- when the multi factor fails, a message will be appended inside this div -->
        <div align="center">
          <h6 v-if="validationCompleted && !validationPassed" class="text-subtitle-2" data-test-id="validationForm.text.revalidateMessage">Please correct the items shown above and revalidate.</h6>
          <v-btn data-test-id="validationForm.btn.validate" v-if="signedIn" v-on:click="completeChecks" color="#d12127" :disabled="(!validationCompleted && runningChecks)|| enableValidateBtn" outlined>
            {{validationButtonMessage}}
          </v-btn>
        </div>
      </v-row>
      <v-row v-else justify="center">
        <div align="center">
          <h6 class="text-h6 text-light">Want to validate other prerequisites?</h6>
          <v-btn color="#d12127" outlined v-on:click="resetChecks"><router-link to="/">validate</router-link></v-btn>
        </div>
      </v-row>
    </section>
  </v-container>
</template>

<script>
  import * as CallAPI from '../../utils/callAPI';
  import * as auth from '../../auth/auth';
  import VerificationCriteria from './components/VerificationCriteria.vue';

  export default {
    name: 'TeamsCqdVerification',

    data () {
      return {
        checks: {
          checkAzureActiveDirectory:'',
          checkMultiFactorDisabled:'',
          checkNotFederated:'',
          checkCorrectlyAssigned:''
        },
        signedIn : false,
        checkAll : '',
        validationPassed: false,
        validationButtonMessage: 'validate',
        adminEmail: '',
        validationCompleted: false,
        runningChecks: false,
        failMessage: '',
        cqdEmail: '',
        msg: '',
        enableValidateBtn: true,
        disableInput: false
      };
    },
    methods: {
      async SignIn () {     
        this.adminEmail = await auth.signIn();
        if(this.adminEmail){
          this.signedIn = true;
        } else {
          this.signedIn = false;
        }
      },
      async SignOut () { 
        await auth.signOut().then(this.signedIn = false);  
        this.adminEmail = '';
      },

     async completeChecks () {
       if(this.validationCompleted && !this.validationPassed) {
         this.resetChecks();
         this.validationCompleted = false;
         return;
       }
        this.failMessage = '';
        this.validationCompleted = false;
        this.runningChecks = true;
        this.resetChecks();
        await CallAPI.checkall(this.cqdEmail);
        if(CallAPI.checkCqdAccountError!==''){
          this.runningChecks = false;
          this.msg = "This cqd account does not exist under admin "+this.adminEmail;
          return;
        }
        let checksPassed = 0;
        Object.keys(this.checks).forEach(key => {
          if(CallAPI.checksMap.get(key)==true){
            this.checks[key] = 'valid';
            checksPassed = checksPassed+1;
          } else {
            this.checks[key] = 'invalid'
          }
        });

        if(checksPassed == 4) {
          this.validationPassed = true;
        } else {
          this.validationPassed = false;
          this.validationButtonMessage = 'revalidate'
        }

        if(typeof CallAPI.checksMap.get('checkMultiFactorDisabled') === 'string' ){ 
          this.failMessage = CallAPI.checksMap.get('checkMultiFactorDisabled');
        }
        
        this.runningChecks = false;
        this.validationCompleted = true;
      },

      resetChecks() {
        Object.keys(this.checks).forEach(key => {this.checks[key] = '';});
      },
      validateEmail(value){
        let validationMessage = null;
        // eslint-disable-next-line no-useless-escape
        if (/^(([^<>()[\]\\.,;:\s@']+(\.[^<>()\\[\]\\.,;:\s@']+)*)|('.+'))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/.test(value))
        {
          this.enableValidateBtn = false;
        } else {
          validationMessage ='Invalid Email Address';
        }
        return validationMessage;
      }

    },
    async mounted (){
      this.adminEmail = await CallAPI.selectAccount();

      if (this.adminEmail) {
        this.signedIn = true;
      } else {
        this.$router.push("/")
      }
    },
    components: {
      VerificationCriteria
    },
    
  };
  
</script>

<style scoped>

.v-btn__content .router-link-active {
  text-decoration: none;
  color: #d12127;
}

.v-application a {
  color: #d12127;
}

</style>
